(function () {
  'use strict';

  angular
    .module('messages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('messages', {
        url: '/messages',
        templateUrl: 'messages/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'messages'
      });
  }
}());
